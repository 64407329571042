<mat-toolbar color="primary" class="app-toolbar" fxLayout="row" fxLayoutAlign="stretch center" *ngIf="isLoggedIn">
  <div fxLayout="row" fxLayoutAlign="stretch center">
    <a [routerLink]="[ basicRouting ]" fxHide.lt-md>
      <img src="./assets/img/logo.svg" alt="Fortesza" style="float: left; margin-top: -5px">
      <img class="hide-logo" src="./assets/img/fortesza-logo.png" alt="Fortesza" height="30">
    </a>
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleMenu()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  </div>
  <div fxFlex fxHide.gt-sm fxLayoutAlign="end center">
    <a [routerLink]="['/']"><img src="./assets/img/logo.svg" alt="Fortesza" style="float: left; margin-top: -5px"></a>
  </div>
  <div fxFlex fxLayoutAlign="end">
    <!-- <button mat-button fxHide.lt-md>Llámanos (+507) 310-0852</button> -->
    <button mat-button [matMenuTriggerFor]="menuUser" class="profile-text side-space" #userTrigger="matMenuTrigger">
      <mat-icon>account_circle</mat-icon>
      {{usr.name}}
      <!--Markarn Doe-->
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menuUser="matMenu" yPosition="below" xPosition="before" [overlapTrigger]="false">
      <div>
        <button mat-menu-item (click)="openChangePasswordDialog()">Cambiar Contraseña</button>
        <button mat-menu-item (click)="logout()">Cerrar Sesión</button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" *ngIf="isLoggedIn">
  <mat-sidenav #drawerOut class="sidenav" fixedInViewport="true" [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [mode]="(isHandset | async)!?.matches ? 'over' : 'side'"
    [opened]="!(isHandset | async)!?.matches && isntDashOrSumm">
    <mat-nav-list *ngIf="usr.user_type == 1" style="margin-top: 64px;">
      <!--validate here-->
      <a mat-list-item [routerLink]="['/dashboard/home']" routerLinkActive="active-link"
        (click)="isMobileClose(); closeAll()">
        <mat-icon class="icon-menu">bar_chart</mat-icon>
        <span>Resumen General</span>
      </a>

      <a mat-list-item [routerLink]="['/dashboard/billing']" routerLinkActive="active-link"
        (click)="isMobileClose(); closeAll()">
        <mat-icon class="icon-menu">star_half</mat-icon>
        <span>Mis facturas</span>
      </a>


      <mat-accordion>
        <mat-expansion-panel hideToggle="true" #expansionO class="sub_level custom-header" [expanded]="expandedOfert">
          <mat-expansion-panel-header (click)="closeOtherExpansionPanels('expansionO')"
            [routerLink]="['/dashboard/active-offers']">
            <mat-panel-title>
              <mat-icon class="icon-menu">trending_up</mat-icon>
              <span>Ofertas</span>
            </mat-panel-title>

            <mat-panel-description>
              <span *ngIf="!expansionO.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span>
              </span>
              <span *ngIf="expansionO.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <a mat-list-item [routerLink]="['/dashboard/active-offers']" routerLinkActive="active-link"
              (click)="isMobileClose()">
              <span style="margin-left: 20px;">Ofertas</span>
            </a>
            <a mat-list-item [routerLink]="['/dashboard/history-offers']" routerLinkActive="active-link"
              (click)="isMobileClose()">
              <span style="margin-left: 20px;">Historial</span>
            </a>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- <mat-accordion>
        <mat-expansion-panel hideToggle="true" #expansion2 class="custom-header" [expanded]="expanded2" [routerLink]="['/dashboard/kyc/auth']">
          <mat-expansion-panel-header (click)="closeOtherExpansionPanels('expansion2')">
            <mat-panel-title>
              <mat-icon class="icon-menu">account_circle</mat-icon>
              <span>Debida Diligencia</span>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!expansion2.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span>
              </span>
              <span *ngIf="expansion2.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <a mat-list-item [routerLink]="['/dashboard/kyc/start-kyc']" routerLinkActive="active-link"
              (click)="isMobileClose()">
              <mat-icon class="icon-menu">account_box</mat-icon>
              <span>Info. General</span>
            </a>
            <a *ngIf="kycController!=null && !kycController" mat-list-item
              [routerLink]="(kycController===false && kycStep >= 0) ? ['/dashboard/kyc/simplified']: null"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: kycController }"
              (click)="isMobileClose()" [class.disabled-anchor]="(kycController===false && kycStep < 0)">
              <mat-icon class="icon-menu">account_box</mat-icon>
              <span>Simplificado</span>
            </a>
            <a *ngIf="kycController!=null && kycController" mat-list-item
              [routerLink]="(kycController===true && kycStep >= 0) ? ['/dashboard/kyc/standard'] : null"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: kycController }"
              (click)="isMobileClose()" [class.disabled-anchor]="(kycController===true && kycStep < 0)">
              <mat-icon class="icon-menu">account_box</mat-icon>
              <span>Estándar</span>
            </a>
            <a *ngIf="kycController!=null && kycController" mat-list-item
              [routerLink]=" (kycController===true && kycStep >= 4) ? ['/dashboard/kyc/financial'] : null"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: kycController }"
              (click)="isMobileClose()" [class.disabled-anchor]="(kycController===true && kycStep < 4)">
              <mat-icon class="icon-menu">account_box</mat-icon>
              <span>Financiera</span>
            </a>
            <a *ngIf="kycController!=null" mat-list-item
              [routerLink]=" (kycController===true && kycStep >= 6) || (kycController===false && kycStep >= 2) ? ['/dashboard/kyc/identity']: null"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: kycController }"
              (click)="isMobileClose()"
              [class.disabled-anchor]="(kycController===true && kycStep < 6) || (kycController===false && kycStep < 2)">
              <mat-icon class="icon-menu">account_box</mat-icon>
              <span>Documentos</span>
            </a>
            <a *ngIf="kycController!=null && showReference" mat-list-item
              [routerLink]=" (kycController===true && kycStep >= 6) || (kycController===false && kycStep >= 2) ? ['/dashboard/kyc/reference']: null"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: kycController }"
              (click)="isMobileClose()"
              [class.disabled-anchor]="(kycController===true && kycStep < 6) || (kycController===false && kycStep < 2)">
              <mat-icon class="icon-menu">account_box</mat-icon>
              <span>Referencia</span>
            </a>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion> -->

      <a mat-list-item [routerLink]="['/dashboard/kyc/auth']" routerLinkActive="active-link"
      (click)="isMobileClose(); closeAll()">
      <mat-icon class="icon-menu">admin_panel_settings</mat-icon>
      <span>Debida Diligencia</span>
      </a>

      <mat-accordion>
        <mat-expansion-panel hideToggle="true" #expansion3 class="sub_level custom-header" [expanded]="expanded3">
          <mat-expansion-panel-header (click)="routeWallet(); closeOtherExpansionPanels('expansion3')">
            <mat-panel-title>
              <mat-icon class="icon-menu">account_balance_wallet</mat-icon>
              <span>Wallet</span>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!expansion3.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span>
              </span>
              <span *ngIf="expansion3.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <a mat-list-item [routerLink]="['/dashboard/wallet/add-balance']" routerLinkActive="active-link"
              (click)="isMobileClose()">
              <mat-icon class="icon-menu">payment</mat-icon>
              <span>{{this.usr.user_type === 1 ? 'Ingresar Saldo' : 'Acreditar Saldo'}}</span>
            </a>
            <a mat-list-item [routerLink]="['/dashboard/wallet/bank-account']" routerLinkActive="active-link"
              (click)="isMobileClose()">
              <mat-icon class="icon-menu">account_balance</mat-icon>
              <span>Cuenta Bancaria</span>
            </a>
            <a mat-list-item [routerLink]="['/dashboard/wallet/account-movements']" routerLinkActive="active-link"
              (click)="isMobileClose()">
              <mat-icon class="icon-menu">description</mat-icon>
              <span>Movimientos</span>
            </a>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>

      <a mat-list-item (click)="logout();">
        <mat-icon class="icon-menu">power_settings_new</mat-icon>
        <span>Cerrar Sesión</span>
      </a>
    </mat-nav-list>

    <mat-nav-list *ngIf="usr.user_type == 2" style="margin-top: 64px;">
      <a mat-list-item [routerLink]="['/dashboard/home']" routerLinkActive="active-link" (click)="isMobileClose()">
        <mat-icon class="icon-menu">trending_up</mat-icon>
        <span>Resumen General</span>
      </a>
      <mat-accordion>
        <mat-expansion-panel hideToggle="true" #expansion2 class="sub_level" [expanded]="expanded2">
          <mat-expansion-panel-header (click)="closeOtherExpansionPanels('expansion2')">
            <mat-panel-title>
              <mat-icon class="icon-menu">bar_chart</mat-icon>
              <span>Por Cobrar</span>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!expansion2.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span>
              </span>
              <span *ngIf="expansion2.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item [routerLink]="['/dashboard/billing/clients/receivable/admi']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">group</mat-icon>
            <span>Clientes</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/billing/receivable']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">description</mat-icon>
            <span>Todas</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/billing/receivable/financed']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">star_half</mat-icon>
            <span>Financiadas</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/billing/assistant/receivable/new']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">multiline_chart</mat-icon>
            <span>Ingresar Facturas</span>
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion *ngIf="showPayable">
        <mat-expansion-panel hideToggle="true" #expansion3 class="custom-header" [expanded]="expanded3">
          <mat-expansion-panel-header (click)="closeOtherExpansionPanels('expansion3')">
            <mat-panel-title>
              <mat-icon class="icon-menu">bar_chart</mat-icon>
              <span>Por Pagar</span>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!expansion3.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span>
              </span>
              <span *ngIf="expansion3.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item [routerLink]="['/dashboard/billing/clients/payable/admi']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">group</mat-icon>
            <span>Clientes</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/billing/payable']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">star_half</mat-icon>
            <span>Mis facturas</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/billing/assistant/payable/new']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">multiline_chart</mat-icon>
            <span>Ingresar Facturas</span>
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel hideToggle="true" #expansion4 class="custom-header" [expanded]="expanded4">
          <mat-expansion-panel-header (click)="routeWallet(); closeOtherExpansionPanels('expansion4')">
            <mat-panel-title>
              <mat-icon class="icon-menu">account_balance_wallet</mat-icon>
              <span>Wallet</span>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!expansion4.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span>
              </span>
              <span *ngIf="expansion4.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item [routerLink]="['/dashboard/wallet/add-balance']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">payment</mat-icon>
            <span>Ingresar Saldo</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/wallet/bank-account']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">account_balance</mat-icon>
            <span>Cuenta Bancaria</span>
          </a>
          <a mat-list-item [routerLink]="['/dashboard/wallet/account-movements']" routerLinkActive="active-link"
            (click)="isMobileClose()">
            <mat-icon class="icon-menu">description</mat-icon>
            <span>Movimientos</span>
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel hideToggle="true" #expansion5 class="custom-header" [expanded]="expanded5">
          <mat-expansion-panel-header (click)="closeOtherExpansionPanels('expansion5')">
            <mat-panel-title>
              <mat-icon class="icon-menu">account_circle</mat-icon>
              <span>Admin</span>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!expansion5.expanded"><span class="material-icons" aria-hidden="true">
                  keyboard_arrow_right
                </span></span>
              <span *ngIf="expansion5.expanded">
                <span class="material-icons" aria-hidden="true">
                  keyboard_arrow_down
                </span>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item [routerLink]="['/dashboard/admin/users']" routerLinkActive="active-link" (click)="isMobileClose()">
            <mat-icon class="icon-menu">perm_identity</mat-icon>
            <span>Usuarios</span>
          </a>
          <!-- <a mat-list-item [routerLink]="['/dashboard/admin/account-manager']" routerLinkActive="active-link" (click)="isMobileClose()">
            <mat-icon class="icon-menu">manage_accounts</mat-icon>
            <span>Configuración</span>
          </a> -->
        </mat-expansion-panel>
      </mat-accordion>
      <a mat-list-item (click)="logout();">
        <mat-icon class="icon-menu">power_settings_new</mat-icon>
        <span>Cerrar Sesión</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content select="route-container"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
