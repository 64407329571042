import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BrowserDetectedService {
  constructor() {}
  isIE() {
    return /*@cc_on!@*/ false || !!(document as any).documentMode;
  }
}
