import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class KycApiService {
  constructor(private readonly http: HttpClient) {}

  createKycSimpleNatural2(body) {
    return this.http.put(`${environment.apiUrl}/set-type/user`, body);
  }

  createKycSimpleNatural3(body) {
    return this.http.put(`${environment.apiUrl}/natural`, body);
  }

  getKycSimpleNatural() {
    return this.http.get(`${environment.apiUrl}/natural`);
  }

  updateKycSimplified(step, data) {
    return this.http.put(`${environment.apiUrl}/natural/${step}/simple`, data);
  }

  loadKycSimplified(step) {
    return this.http.get(`${environment.apiUrl}/natural/${step}/simple`);
  }

  updateKycExtend(step, data) {
    return this.http.put(`${environment.apiUrl}/natural/${step}/extend`, data);
  }

  updateAttached(step, data) {
    return this.http.post(`${environment.apiUrl}/natural/${step}/extend`, data);
  }

  loadKycExtend(step) {
    return this.http.get(`${environment.apiUrl}/natural/${step}/extend`);
  }
}
