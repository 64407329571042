import { Subscription } from "rxjs";
import { KycStartManagerService } from "src/app/shared/services/managers/kyc-start-manager/kyc-start-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { AfterViewChecked, Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "./shared/services/auth/auth.service";
import { KycUpdateDialogComponent } from "./shared/components/kyc-update-dialog/kyc-update-dialog.component";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements AfterViewChecked, OnInit, OnDestroy {
  private isLoggedIn = false;
  public usr: any;
  public subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly kycStartManager: KycStartManagerService
  ) {
    this.authService.getIsLoggedIn().subscribe(rs => {
      this.isLoggedIn = rs;
    });
    this.authService.getUser().subscribe(usr => {
      this.usr = usr;
    });
  }

  ngOnInit() {
    const user = localStorage.getItem("currentSession")
      ? JSON.parse(localStorage.getItem("currentSession"))
      : null;

    if (user) {
      this.subscription.add(
        this.kycStartManager.getKycStart().subscribe(res => {
          if (res && res.kyc_upgrade) {
            this.dialog
              .open(KycUpdateDialogComponent, {
                width: "90%",
                maxWidth: "640px",
                data: {
                  name: user.name ? user.name : "Usuario"
                }
              })
              .afterClosed()
              .subscribe(res => {});
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if (window["dataLayer"]) {
      window["dataLayer"].push({ event: "optimize.activate" });
    }
  }
}
