import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { KycApiService } from "../../apis/kyc-api.service";
import { AuthService } from "../../auth/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { RedirectoService } from "../redirecto/redirecto.service";

@Injectable({
  providedIn: "root"
})
export class KycStartManagerService {
  private readonly _kycStart: BehaviorSubject<KycStart> = new BehaviorSubject({
    type: null,
    hasKyc: null,
    has_completed_kyc: null,
    kyc_status: null,
    kyc_upgrade: null,
    waiting_verify: null,
    kyc_step: null,
    need_all_docs: null,
    need_income_c: null,
    need_dir_c: null,
    approved_reload: null,
    add_balance: null,
    holding_amount: null,
    public_figure: null,
    criminal_case: null
  });
  private kycStatus: boolean;
  private readonly _kycStartResponse: Subject<boolean> = new Subject();
  private readonly _KycStartLoading: BehaviorSubject<
    boolean
  > = new BehaviorSubject(false);
  private readonly _kycStartUpLoading: BehaviorSubject<
    boolean
  > = new BehaviorSubject(false);
  private readonly _KycController: BehaviorSubject<
    boolean
  > = new BehaviorSubject(null);
  private readonly _redirectKyc: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly _kycStep: BehaviorSubject<number> = new BehaviorSubject(
    null
  );
  private readonly has_completed_kyc: BehaviorSubject<
    boolean
  > = new BehaviorSubject(null);
  private isLoggedIn: boolean;
  private usr: any;
  private firtsNav = false;
  private rutaAlt: string = null;

  constructor(
    private readonly kycApi: KycApiService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly redirect: RedirectoService
  ) {
    this.getKycStart().subscribe(_ => this.valueKycController());
    this.authService.getIsLoggedIn().subscribe(logged => {
      this.isLoggedIn = logged;
      if (this.isLoggedIn) {
        this.authService.getKycStatus().subscribe(rs => (this.kycStatus = rs));
        this.authService.getUser().subscribe(rs => {
          this.usr = rs;
        });
        this.isLoggedIn && this.usr.user_type === 1 && this.loadKycStart();
      } else {
        this._kycStart.next({
          type: null,
          hasKyc: null,
          has_completed_kyc: null,
          kyc_status: null,
          kyc_upgrade: null,
          waiting_verify: null,
          kyc_step: null,
          need_all_docs: null,
          need_income_c: null,
          need_dir_c: null,
          approved_reload: null,
          add_balance: null,
          holding_amount: null,
          public_figure: null,
          criminal_case: null
        });
        this.has_completed_kyc.next(null);
      }
    });
  }
  setKycStep(val: number) {
    if (this._kycStep.getValue() < val) {
      this._kycStep.next(val);
    }
  }

  getKycStep() {
    return this._kycStep.asObservable();
  }
  getIfCompletedKyc() {
    return this.has_completed_kyc.asObservable();
  }
  getKycStart() {
    return this._kycStart.asObservable();
  }

  getKycStartResponse() {
    return this._kycStartResponse.asObservable();
  }

  getKycController() {
    return this._KycController.asObservable();
  }
  getRedirectKyc() {
    return this._redirectKyc.asObservable();
  }

  getKycStartLoading() {
    return this._KycStartLoading.asObservable();
  }
  getKycStartUpLoading() {
    return this._kycStartUpLoading.asObservable();
  }

  updateKycStart2(value) {
    this._kycStartUpLoading.next(true);
    this.kycApi.createKycSimpleNatural2(value).subscribe(
      res => {
        this._kycStartUpLoading.next(false);
        const aux = this._kycStart.getValue();
        aux.type = value.type;
        this._kycStart.next(aux);
        this._kycStartResponse.next(true);
      },
      err => {
        this._kycStartUpLoading.next(false);
        this._kycStartResponse.next(false);
      }
    );
  }

  updateKycStart3(value) {
    this._kycStartUpLoading.next(true);
    this.kycApi.createKycSimpleNatural3(value).subscribe(
      res => {
        this._kycStartUpLoading.next(false);
        const aux = this._kycStart.getValue();
        aux.criminal_case = value.criminal_case;
        aux.holding_amount = value.holding_amount;
        aux.public_figure = value.public_figure;
        this._kycStart.next(aux);
        this.setKycStep(0);
        this._kycStartResponse.next(true);
      },
      err => {
        this._kycStartUpLoading.next(false);
        this._kycStartResponse.next(false);
      }
    );
  }

  loadKycStart() {
    this._KycStartLoading.next(true);
    this.kycApi.getKycSimpleNatural().subscribe(
      (res: any) => {
        if (!res.data.kyc_step) {
          res.data.kyc_step = -1;
        }
        if (res.data.type === 0) {
          res.data.type = 1;
        }
        this._KycStartLoading.next(false);
        this._kycStart.next(res.data);
        this._kycStep.next(res.data.kyc_step);
        this.has_completed_kyc.next(res.data.has_completed_kyc);
      },
      err => {
        this._KycStartLoading.next(false);
      }
    );
  }

  private valueKycController() {
    if (
      this._kycStart.getValue().holding_amount != null &&
      this._kycStart.getValue().public_figure != null &&
      this._kycStart.getValue().criminal_case != null
    ) {
      this._KycController.next(
        this._kycStart.getValue().public_figure === true ||
          this._kycStart.getValue().holding_amount === true ||
          this._kycStart.getValue().criminal_case === true
      );
      const val =
        this._kycStart.getValue().public_figure === true ||
        this._kycStart.getValue().holding_amount === true ||
        this._kycStart.getValue().criminal_case === true;

      const sub = this.redirect.getRuta().subscribe(ruta => {
        this.rutaAlt = ruta;
      });
      sub.unsubscribe();
      this._redirectKyc.next(false);
      if (!this.firtsNav && this.rutaAlt && this.rutaAlt.includes("kyc")) {
        this.firtsNav = true;
        this.router.navigate([this.rutaAlt]);
      }
    } else {
      this._redirectKyc.next(true);
      this._KycController.next(null);
    }
  }
}

export interface KycStart {
  type: number;
  hasKyc: boolean;
  has_completed_kyc: boolean;
  kyc_status: boolean;
  kyc_upgrade: boolean;
  waiting_verify: boolean;
  kyc_step: number;
  need_all_docs: boolean;
  need_income_c: boolean;
  need_dir_c: boolean;
  approved_reload: boolean;
  add_balance: boolean;
  holding_amount: boolean;
  public_figure: boolean;
  criminal_case: boolean;
}
