import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NotSessionRoutingModule } from "./not-session-routing.module";
import { NotSessionComponent } from "./not-session.component";

@NgModule({
  declarations: [NotSessionComponent],
  imports: [
    CommonModule,
    NotSessionRoutingModule,
    RouterModule,
    FlexLayoutModule
  ]
})
export class NotSessionModule {}
