import { AuthService } from "./../../shared/services/auth/auth.service";
import { environment } from "./../../../environments/environment";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-not-session",
  templateUrl: "./not-session.component.html",
  styleUrls: ["./not-session.component.css"],
  providers: [AuthService]
})
export class NotSessionComponent implements OnInit {
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {}

  goBack() {
    this.authService.logout(`${environment.host}/login`);
  }
}
