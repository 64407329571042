import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Route
} from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class NotLoginProdGuard implements CanActivate {
  constructor(private readonly router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!environment.production) {
      // If not production
      return true;
    }

    this.router.navigateByUrl("/page-not-found");
  }
  canLoad(route: Route): boolean {
    if (!environment.production) {
      // If not production
      return true;
    }

    this.router.navigateByUrl("/page-not-found");
    return false;
  }
}
