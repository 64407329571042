import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class GenericDataApiService {
  constructor(private readonly http: HttpClient) {}

  fetchCountry() {
    return this.http.get(`${environment.apiUrl}/countryKyc`);
  }

  fetchTopOfferings() {
    return this.http.get(`${environment.apiUrl}/offerings/top`);
  }

  changePassword(previousPass, password, confirmPassword) {
    const body = {
      old_password: previousPass,
      password,
      password_confirmation: confirmPassword
    };
    return this.http.post<any>(`${environment.apiUrl}/password/change`, body);
  }

  fetchBlogPosts() {
    return this.http.get(`${environment.apiUrl}/blogs/all`);
  }

  fetchPost(id) {
    return this.http.get(`${environment.apiUrl}/blogs/${id}`);
  }
}
