<div fxLayout="column" class="footer-container">
  <p>Legal Privacidad</p>
  <p>
    ("Fortesza") es una empresa especializada en la Igualación de oportunidades para aquellos que se registran como
    miembros de su plataforma. Al utilizar este sitio web, aceptas nuestros Términos de uso y nuestras Política de
    Privacidad. Fortesza es una sociedad anónima registrada en la República de Panamá bajo el nombre de REAL ASSETS
    DEVELOPMENT CORP, con RUC 155645736-2-2017 con dirección en Ph. Kenex Plaza, Piso 9, Avenida Samuel Lewis con Calle
    59 Este, Ciudad de Panamá, Panamá.
  </p>
</div>
