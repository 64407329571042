import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxCurrencyModule } from "ngx-currency";
import { CurrencyMaskConfig } from "ngx-currency/src/currency-mask.config";
import { InvestDialogComponent } from "../../dashboard/offering/invest-dialog/invest-dialog.component";
import { MaterialModule } from "../material/material.module";
import { CarouselComponent } from "./carousel/carousel.component";
import { DocumentDialogComponent } from "./document-dialog/document-dialog.component";
import { Footer2Component } from "./footer2/footer2.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { Navbar2Component } from "./navbar2/navbar2.component";
import { NoDocumentDialogComponent } from "./no-document-dialog/no-document-dialog.component";
import { VideoComponent } from "./video/video.component";
import { HeaderKycComponent } from "./header-kyc/header-kyc.component";
import { KycProgressBarComponent } from "./kyc-progress-bar/kyc-progress-bar.component";
import { FooterKycComponent } from "./footer-kyc/footer-kyc.component";

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: false,
  decimal: ".",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ",",
  nullable: false
};
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FormsModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig)
    ],
    declarations: [
        NavbarComponent,
        VideoComponent,
        DocumentDialogComponent,
        InvestDialogComponent,
        CarouselComponent,
        NoDocumentDialogComponent,
        Navbar2Component,
        Footer2Component,
        HeaderKycComponent,
        KycProgressBarComponent,
        FooterKycComponent
    ],
    exports: [
        NavbarComponent,
        DocumentDialogComponent,
        CarouselComponent,
        Navbar2Component,
        Footer2Component,
        HeaderKycComponent,
        KycProgressBarComponent,
        FooterKycComponent
    ]
})
export class ComponentsModule {}
