import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Component, Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/auth.service";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import "rxjs/add/operator/catch";

@Injectable()
export class ModalsInterceptor implements HttpInterceptor {
  private email: string;
  constructor(
    public interceptDialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly authService: AuthService,
    public router: Router
  ) {
    this.authService.getEmail().subscribe(rs => (this.email = rs));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        map(event => {
          if (
            event instanceof HttpResponse &&
            this.shouldBeInterceptedWModal(event)
          ) {
            this.openInterceptDialog(
              event.body.data.title,
              event.body.data.text,
              event.body.data.imgURL,
              event.body.data.text2
            );
          }

          return event;
        })
      )
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          if (
            this.shouldBeInterceptedWPopup(err) &&
            err.error.popupType === "danger"
          ) {
            err.error.message.map(message => {
              this.toastrService.error(message, "Error!");
            });
          }

          if (this.shouldSetConfirmationMsg(err)) {
            this.authService.setConfirmMsg(err.error.need_confirmation);
            this.authService.setEmail(this.email);
            this.router.navigate(["confirm"]);
          }
        }

        if (
          err instanceof HttpErrorResponse &&
          this.shouldBeInterceptedWModalErr(err)
        ) {
          this.openInterceptDialog(
            err.error.data.title,
            err.error.data.text,
            err.error.data.imgURL,
            err.error.data.text2 ? err.error.data.text2 : null
          );
        }

        if (err instanceof HttpErrorResponse && this.isInternal(err)) {
          const message = "Algo salió mal, contacte al administrador.";
          this.toastrService.error(message, "Error!");
        }

        if (err instanceof HttpErrorResponse && this.isDisconnected(err)) {
          this.router.navigate(["not-session"]);
        }
        return throwError(err);
      });
  }
  private isDisconnected(err: HttpErrorResponse) {
    if (err.status == 401 && err.error.error == "invalid_credentials") {
      return true;
    }
    return false;
  }
  private isInternal(err: HttpErrorResponse) {
    if (~~(err.status / 100) == 5) {
      return true;
    }
    return false;
  }
  private shouldSetConfirmationMsg(err: HttpErrorResponse) {
    if (err.error.need_confirmation) {
      return true;
    }
    return false;
  }

  private shouldBeInterceptedWModal(event: HttpResponse<any>) {
    if (~~(event.status / 100) == 2 && event.body.dialog) {
      return true;
    }
    return false;
  }

  private shouldBeInterceptedWModalErr(err: HttpErrorResponse) {
    if (~~(err.status / 100) == 4 && err.error.dialog) {
      return true;
    }
    return false;
  }

  private shouldBeInterceptedWPopup(err: HttpErrorResponse) {
    if (~~(err.status / 100) == 4 && err.error.popup) {
      return true;
    }
    return false;
  }

  openInterceptDialog(title, text, img, text2): void {
    const interceptDialogRef = this.interceptDialog.open(InterceptDialog, {
      id: "InterceptDialog",
      width: "500px",
      data: { title: title, text: text, img: img, text2: text2 }
    });

    interceptDialogRef.afterClosed().subscribe(result => {});
  }
}

/*INTERCEPT DIALOG*/
@Component({
  selector: "intercept-dialog",
  templateUrl: "interceptDialog.html"
})
export class InterceptDialog {
  constructor(
    public interceptDialogRef: MatDialogRef<InterceptDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.interceptDialogRef.close();
  }
  confirm(): void {
    this.interceptDialogRef.close();
  }
}
