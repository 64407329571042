import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener
} from "@angular/core";
import * as $ from "jquery";
import { AuthService } from "../../services/auth/auth.service";
import { GenericDataManagerService } from "../../services/managers/generic-data-manager/generic-data-manager.service";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { KycStartManagerService } from "../../services/managers/kyc-start-manager/kyc-start-manager.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  @Input() login = false;
  @Input() register = false;
  @Input() gradient = false;
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  public kycController: boolean = null;
  public kycStep: number = null;
  showPayable: boolean = true;
  @ViewChild("drawerOut") sidenavOut: MatSidenav;
  isLoggedIn = false;
  public isntDashOrSumm = false;
  usr: any;
  basicRouting: string;
  public isColored: boolean;
  private _url: string;
  private readonly _alwaysColored: any = [
    "education",
    "us",
    "blog",
    "regulations"
  ];
  private _coloredFlag = false;
  public _isMovilView = false;
  isSummary: boolean;
  contentOutlet: string;
  expanded2: boolean;
  expanded3: boolean;
  expanded4: boolean;
  expanded5: boolean;
  expandedOfert: boolean;

  showReference = false;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly authService: AuthService,
    private readonly kycManager: KycStartManagerService,
    private readonly gDataManager: GenericDataManagerService,
    public ChangePasswordDialog: MatDialog,
    public LoginDialog: MatDialog,
    private readonly router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._coloredFlag = false;
        this._alwaysColored.forEach(el => {
          if (this.router.url.indexOf(el) !== -1) {
            this._coloredFlag = true;
          }
        });
        if (this._coloredFlag) {
          this.isColored = true;
        } else {
          this.isColored = false;
        }
        window.scrollTo(0, 0);
      }
      this.showLinkReference();
    });
  }

  ngOnInit() {
    this._url = this.router.url;

    if (this.router.url === "/dashboard/summary") {
      this.isSummary = true;
      this.contentOutlet = "summary-container";
    } else {
      this.isSummary = false;
      this.contentOutlet = "route-container";
    }
    const substring = "dashboard";
    if (
      this.router.url === "/dashboard/summary" ||
      this._url.indexOf(substring) === -1
    ) {
      this.isntDashOrSumm = false;
    } else {
      this.isntDashOrSumm = true;
    }
    this.kycManager
      .getKycController()
      .subscribe(val => (this.kycController = val));
    this.authService.getIsLoggedIn().subscribe(rs => (this.isLoggedIn = rs));
    this.authService.getUser().subscribe(rs => {
      this.usr = rs;
      if (this.usr.user_type === 1) {
        this.basicRouting = "/dashboard/summary";
      }
      if (this.usr.user_type === 2) {
        this.basicRouting = "/dashboard/billing/receivable";
      }
    });
    this.kycManager.getKycStep().subscribe(val => {
      this.kycStep = val;
    });
  }

  @HostListener("window:scroll") scroll() {
    if (this._coloredFlag) {
      return;
    }
    if (
      window.scrollY >
      ($("html").prop("scrollHeight") - $(window).height()) * 0.009
    ) {
      this.isColored = true;
      if (this._isMovilView) {
        this._isMovilView = true;
      }
      $(".navbar-fortesza").css({
        background:
          "linear-gradient(90deg, #162d3d 0%, #21435c 56%, #21435c 100%) !important"
      });
    } else {
      this.isColored = false;
      $(".navbar-fortesza").css({ background: "transparent !important" });
    }
  }

  showMenu() {
    const x = document.getElementById("navDemo");
    if (x.className.indexOf("w3-show") === -1) {
      x.className += " w3-show";
      this.isColored = true;
      this._isMovilView = true;
    } else {
      x.className = x.className.replace(" w3-show", "");
      this._isMovilView = false;
      if (
        document.documentElement.scrollTop <
        ($("html").prop("scrollHeight") - $(window).height()) * 0.03
      ) {
        if (!this._coloredFlag) {
          this.isColored = false;
        }
      }
    }
  }
  closeSide() {
    const x = document.getElementById("navDemo");
    x.className = x.className.replace(" w3-show", "");
  }

  regType(val) {
    this.gDataManager.setRegType(val);
  }

  openChangePasswordDialog() {
    const ChangePasswordDialogRef = this.ChangePasswordDialog.open(
      ChangePasswordComponent,
      {
        width: "500px",
        data: {}
      }
    );

    ChangePasswordDialogRef.afterClosed().subscribe(result => {});
  }

  logout() {
    this.authService.logout();
  }
  isMobileClose() {
    if (this.sidenavOut.mode === "over") {
      this.sidenavOut.close();
    }
  }
  toggleMenu() {
    this.sidenavOut.toggle();
  }

  navRegister() {
    this.showMenu();
    this.router.navigate(["/register"]);
  }
  routeWallet() {
    if (this.usr.user_type === 1) {
      if (!this.expanded3) {
        this.router.navigate(["/dashboard/wallet/add-balance"]);
      }
    }
    if (this.usr.user_type === 2) {
      if (!this.expanded4) {
        this.router.navigate(["/dashboard/wallet/add-balance"]);
      }
    }
  }
  closeOtherExpansionPanels(clickfrom: string) {
    if (this.usr.user_type === 1) {
      if (clickfrom === "expansion3") {
        this.expanded2 = false;
        this.expanded3 = true;
        this.expandedOfert = false;
      }
      if (clickfrom === "expansion2") {
        this.expanded3 = false;
        this.expanded2 = true;
        this.expandedOfert = false;
      }
      if (clickfrom === "expansionO") {
        this.expanded2 = false;
        this.expanded3 = false;
        this.expandedOfert = true;
      }
    }
    if (this.usr.user_type === 2) {
      if (clickfrom === "expansion3") {
        this.expanded2 = false;
        this.expanded3 = true;
        this.expanded4 = false;
        this.expanded5 = false;
      }
      if (clickfrom === "expansion2") {
        this.expanded2 = true;
        this.expanded3 = false;
        this.expanded4 = false;
        this.expanded5 = false;
      }
      if (clickfrom === "expansion4") {
        this.expanded2 = false;
        this.expanded3 = false;
        this.expanded4 = true;
        this.expanded5 = false;
      }
      if (clickfrom === "expansion5") {
        this.expanded2 = false;
        this.expanded3 = false;
        this.expanded4 = false;
        this.expanded5 = true;
      }
    }
  }
  openLoginDialog() {}

  closeAll() {
    this.expanded2 = this.expanded3 = this.expandedOfert = false;
  }

  gotoBlog(): void {
    window.open("https://blog.fortesza.com", "_blank");
  }

  showLinkReference() {
    const user = localStorage.getItem("currentSession")
      ? JSON.parse(localStorage.getItem("currentSession"))
      : null;

    if (user) {

      this.showReference = !user.has_reference && !user.is_reference_mandatory;
      if(user.email === 'carlos.ferrer@hdp.com.pa'){
         this.showPayable = true;
      }else{
        this.showPayable = false;

      }
    }
  }
}
