// import * as git from "../assets/git.json";
export const environment = {
  production: true,
  staging: false,
  apiUrl: "https://api.fortesza.com/api/v2",
  envName: "production",
  enableFeatureModuleDev: false,
  // serviceVersion: git.short,
  host: "https://www.fortesza.com",
  idGoogleTagManager: "GTM-M78TSF5",
  whatsApp: { investors: '50763444776', companies: '50763729141' },

  // DDCHECK
  ddcheckApiUrl: "https://api.ddcheck.com/api/v1",
  welcomeRoute: "dashboard/kyc/survey",
  questionRoute: "dashboard/kyc/question",
  redirectToFinish: false, // si esta propiedad es false redirige a finishRoute sino (true) redirecciona a la url configurada en ddcheck
  finishRoute: "/dashboard/home"
};
