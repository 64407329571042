import { Component, OnInit, ViewChild } from "@angular/core";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { AuthService } from "../../shared/services/auth/auth.service";
import { KycStartManagerService } from "../../shared/services/managers/kyc-start-manager/kyc-start-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ChangePasswordComponent } from "../../shared/components/change-password/change-password.component";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-user-layout",
  templateUrl: "./user-layout.component.html",
  styleUrls: ["./user-layout.component.css"]
})
export class UserLayoutComponent implements OnInit {
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  public kycController: boolean = null;
  public usr: any;
  public isSummary = false;
  @ViewChild("drawer") sidenav: MatSidenav;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly authService: AuthService,
    private readonly kycManager: KycStartManagerService,
    public ChangePasswordDialog: MatDialog,
    private readonly router: Router
  ) {
    this.authService.getUser().subscribe(usr => {
      this.usr = usr;
    });
  }

  ngOnInit() {
    if (this.router.url === "/dashboard/summary") {
      this.isSummary = true;
    } else {
      this.isSummary = false;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === "/dashboard/summary") {
          this.isSummary = true;
        } else {
          this.isSummary = false;
        }
      }
    });
    this.kycManager
      .getKycController()
      .subscribe(val => (this.kycController = val));
  }

  logout() {
    this.authService.logout();
  }

  isMobileClose() {
    if (this.sidenav.mode === "over") {
      this.sidenav.close();
    }
  }
  openChangePasswordDialog() {
    const ChangePasswordDialogRef = this.ChangePasswordDialog.open(
      ChangePasswordComponent,
      {
        width: "500px",
        data: {}
      }
    );

    ChangePasswordDialogRef.afterClosed().subscribe(result => {});
  }
}
