import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as inter from "@ddcheck/common";
import { Sweetalert, AuthService, StorageService } from "@ddcheck/common";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class PrivateInterceptor implements HttpInterceptor {
  private errorResponse: string;

  constructor(
    private storage: StorageService,
    private _authService: AuthService,
    private alert: Sweetalert,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.storage.setItem(inter.INTERCEPTOR_TYPE, inter.INTERCEPTOR_CUSTOMER);
    const token = this.storage.getItem("session_customer");

    if (token && request.url.includes("ddcheck.com")) {
      //
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      return next.handle(request).pipe(
        tap(
          (event: HttpEvent<any>) => {},
          (err: HttpErrorResponse) => {
            if (err instanceof HttpErrorResponse) {
              const errores = err.error.errors;

              if (err.status == 401) {
                console.warn("Inautorizado");
              } else {
                if (err.status === 0) {
                  console.warn("Sin conexion a internet");
                  return;
                }
              }

              if (errores) {
                this.errorResponse = "";
                for (const error in errores) {
                  this.errorResponse += errores[error] + "\n";
                }
                this.alert.error(this.errorResponse);
              } else {
                if (err.error.message) {
                  this.alert.error(err.error.message);
                }
              }
            }
          }
        )
      );
    } else if (request.url.includes("fortesza.com")) {
      request = request.clone({
        withCredentials: true
      });

      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
