import { NotLoginProdGuard } from "./shared/services/auth/not-login-prod.guard";
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { UserLayoutComponent } from "./layouts/user-layout/user-layout.component";
import { AuthLazyGuard } from "./shared/services/auth/auth-lazy.guard";
import { BrowserGuard } from "./shared/services/guards/browser-guard/browser.guard";
import { KycLayoutComponent } from "./layouts/kyc-layout/kyc-layout.component";
import { UsersProtectionGuard } from "./shared/services/guards/users-protection-guard/users-protection.guard";

export const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "login-for-testing", // Login only for dev and staging
    canLoad: [NotLoginProdGuard],
    loadChildren: () =>
      import("./pages/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "dashboard",
    component: UserLayoutComponent,
    canLoad: [AuthLazyGuard],
    canActivate: [BrowserGuard],
    canActivateChild: [UsersProtectionGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: "kyc",
    component: KycLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./kyc-revolution/kyc-revolution.module").then(
            m => m.KycRevolutionModule
          )
      }
    ]
  },
  {
    path: "not-session",
    loadChildren: () =>
      import("./pages/not-session/not-session.module").then(
        m => m.NotSessionModule
      )
  },
  {
    path: "**",
    redirectTo: "page-not-found"
  },
  {
    path: "page-not-found",
    loadChildren: () => import("./pages/pages.module").then(m => m.PagesModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
