<section style="position: relative;">
    <section *ngIf="isLoading" fxFlex fxLayout="row" fxLayoutAlign="center center" class="locked">
        <mat-spinner [strokeWidth]="5"></mat-spinner>
    </section>
<h1 mat-dialog-title>Cambiar Contraseña</h1>
<div mat-dialog-content>
    <form [formGroup]="fomrsGroup[0]">
        <mat-form-field fxLayout="column" fxFlexFill class="matform-space">
            <input autocomplete="off"  matInput placeholder="Contraseña Anterior" formControlName="previousPass" required type="password">
            <mat-error *ngIf="!fomrsGroup[0].hasError('required')">Campo Requerido</mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="column" fxFlexFill class="matform-space">
            <input autocomplete="off"  matInput placeholder="Nueva Contraseña" formControlName="password" required type="password">
            <mat-error *ngIf="!fomrsGroup[0].hasError('required')">Campo Requerido</mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="column" fxFlexFill class="matform-space">
            <input autocomplete="off"  matInput placeholder="Confirmar Nueva Contraseña" formControlName="confirm_password" required type="password">
            <mat-error *ngIf="fomrsGroup[0].get('confirm_password').hasError('MatchPassword')">Las contraseñas no coinciden</mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="center">
    <button mat-raised-button (click)="confirm()" cdkFocusInitial [disabled]="!disableBtn">ACEPTAR</button>
</div>
</section>