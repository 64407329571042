import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-kyc-update-dialog",
  templateUrl: "./kyc-update-dialog.component.html",
  styleUrls: ["./kyc-update-dialog.component.css"]
})
export class KycUpdateDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<KycUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close();
  }
}
