import { environment } from "src/environments/environment";
import { Injectable, Inject } from "@angular/core";

@Injectable()
export class HostnameService {
  constructor() {}

  getHostname(): string {
    return environment.host;
  }
}
