import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-whatsapp-button",
  templateUrl: "./whatsapp-button.component.html",
  styleUrls: ["./whatsapp-button.component.scss"]
})
export class WhatsappButtonComponent implements OnInit {
  whatsApp: {
    investors: string;
    companies: string;
  } = environment.whatsApp;
  phoneNumber: string;
  constructor() {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem("currentSession"));
    if (user && user.user_type === 1 ) {
      this.phoneNumber = this.whatsApp.investors;
    } else {
      this.phoneNumber = this.whatsApp.companies;
    }
  }
}
