import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { KycUpdateDialogRoutingModule } from "./kyc-update-dialog-routing.module";
import { KycUpdateDialogComponent } from "./kyc-update-dialog.component";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "@angular/flex-layout";
@NgModule({
    declarations: [KycUpdateDialogComponent],
    imports: [
        CommonModule,
        KycUpdateDialogRoutingModule,
        FlexLayoutModule,
        MatButtonModule,
        RouterModule,
        MatDialogModule
    ],
    exports: [KycUpdateDialogComponent]
})
export class KycUpdateDialogModule {}
