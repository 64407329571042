import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { IAuthResetPassword } from "../../models/auth.model";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private readonly _resUser: BehaviorSubject<User> = new BehaviorSubject({
    email: "",
    name: "",
    kyc_status: false
  });
  private readonly _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly _resErr: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly _kycStatus: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly _email: BehaviorSubject<string> = new BehaviorSubject("");
  public redirectUrl = "/dashboard/summary";
  private readonly apiUrl = environment.apiUrl;
  public rutaHome = "/dashboard/summary";
  public rutaDashboardHome = "/dashboard/home";
  private readonly _usrLogType: BehaviorSubject<string> = new BehaviorSubject<
    string
  >("");

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly location: Location
  ) {
    const session = localStorage.getItem("currentSession");
    if (session) {
      this._resUser.next(<User>JSON.parse(session));
      this._kycStatus.next(this._resUser.getValue().kyc_status);
      this._isLoggedIn.next(true);

      if (this.location.path() === "") {
        this.router.navigateByUrl("/dashboard/home");
      }
    }
  }

  setEmail(email) {
    this._email.next(email);
  }

  getEmail() {
    return this._email.asObservable();
  }
  setLoginType(val) {
    this._usrLogType.next(val);
  }

  getLoginType() {
    return this._usrLogType.asObservable();
  }

  setKycStatus(val) {
    const session = JSON.parse(localStorage.getItem("currentSession"));
    session.kyc_status = val;
    localStorage.setItem("currentSession", JSON.stringify(session));
    return this._kycStatus.next(val);
  }

  getKycStatus() {
    return this._kycStatus.asObservable();
  }

  setConfirmMsg(val) {
    this._resErr.next(val);
  }
  getConfirmMsg() {
    return this._resErr.asObservable();
  }

  getUser() {
    return this._resUser.asObservable();
  }

  getIsLoggedIn() {
    return this._isLoggedIn.asObservable();
  }

  subLogin(password, tax_id, user_name) {
    this.resetValues();
    this.setEmail(user_name);
    let body;
    body = {
      user_name,
      password,
      tax_id
    };
    return this.http.post<any>(this.apiUrl + "/login_account", body).pipe(
      map(res => {
        // login successful if there's a jwt token in the response
        if (res && res.data) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentSession", JSON.stringify(res.data));
          this._resUser.next(<User>res.data);
          this._kycStatus.next(this._resUser.getValue().kyc_status);
          this._isLoggedIn.next(true);

          if (res.data.user_type === 2) {
            this.redirectUrl = "/dashboard/home";
          } else {
            this.redirectUrl = this.rutaHome;
          }
        }
        return res;
      })
    );
  }

  login(email, password) {
    this.resetValues();
    let body;
    this.setEmail(email);
    body = {
      email,
      password
    };
    return this.http
      .post<any>(this.apiUrl + "/authenticate", body, { observe: "response" })
      .pipe(
        map(res => {
          // login successful if there's a jwt token in the response
          if (res && res.body && res.body.data) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              "currentSession",
              JSON.stringify(res.body.data)
            );
            this._resUser.next(<User>res.body.data);
            this._kycStatus.next(this._resUser.getValue().kyc_status);
            this._isLoggedIn.next(true);

            if (res.body.data.user_type === 2) {
              this.redirectUrl = "/dashboard/home";
            } else if (
              res.body.data.user_type === 1 &&
              res.body.data.kyc_status &&
              res.body.data.kyc_step === 8
            ) {
              this.redirectUrl = this.rutaDashboardHome;
            } else {
              this.redirectUrl = this.rutaHome;
            }
          }
          return res;
        })
      );
  }

  alternativeLogin(res) {
    this.resetValues();
    this.setEmail(res.data.email);
    if (res && res.data) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentSession", JSON.stringify(res.data));
      this._resUser.next(<User>res.data);
      this._kycStatus.next(this._resUser.getValue().kyc_status);
      this._isLoggedIn.next(true);
      if (res.data.user_type === 2) {
        this.redirectUrl = "/kyc/fortesza-welcome";
      } else {
        this.redirectUrl = this.rutaHome;
      }
      // Redirect the user
      this.router.navigate([this.redirectUrl]);
    }
    // return res;
  }

  resetValues() {
    this._resErr.next(false);
    this.setEmail("");
  }
  makeid() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  logout(url?: string): void {
    // #2. set band auth
    this._isLoggedIn.next(false);
    // #3. clear storage
    localStorage.removeItem("currentSession");

    if (localStorage.getItem("docPopup")) {
      localStorage.removeItem("docPopup");
    }

    this._resUser.next(<User>{
      email: "",
      name: ""
    });

    // # redirection
    window.location.href = url ? url : environment.host;
  }

  /**
   * resetPassword
   */
  public resetPassword(reset: IAuthResetPassword): Promise<any> {
    return this.http
      .put(this.apiUrl + "/users/call-reset-password", reset)
      .toPromise();
  }
}

export interface User {
  email: string;
  name: string;
  kyc_status: boolean;
  user_hash?: string;
}
