import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WhatsappButtonComponent } from "./whatsapp-button.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [WhatsappButtonComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [WhatsappButtonComponent]
})
export class WhatsappButtonModule {}
