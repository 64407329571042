import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { BrowserDetectedService } from "../../utility/browser-detected/browser-detected.service";

@Injectable({
  providedIn: "root"
})
export class BrowserGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly browserDetected: BrowserDetectedService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
