import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class RedirectoService {
  constructor(private readonly router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.findRuta(event.url);
      });
  }

  public rutaHome: BehaviorSubject<string> = new BehaviorSubject(null);

  findRuta(url?) {
    if (!this.rutaHome.getValue()) {
      this.rutaHome.next(url);
    }
  }

  getRuta() {
    return this.rutaHome.asObservable();
  }
}
