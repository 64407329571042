import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  KycStartManagerService,
  KycStart
} from "../kyc-start-manager/kyc-start-manager.service";

@Injectable({
  providedIn: "root"
})
export class SidebarManagerService {
  constructor() {}
}
