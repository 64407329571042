import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { GenericDataManagerService } from "../../services/managers/generic-data-manager/generic-data-manager.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  public fomrsGroup: Array<FormGroup> = new Array<FormGroup>(
    this._formBuilder.group(
      {
        previousPass: ["", Validators.required],
        password: ["", Validators.required],
        confirm_password: ["", Validators.required]
      },
      { validator: this.MatchPassword }
    )
  );
  public disableBtn = false;
  public isLoading: boolean;
  constructor(
    public ChangePasswordDialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _formBuilder: FormBuilder,
    private readonly gDataService: GenericDataManagerService
  ) {}

  ngOnInit() {
    this.fomrsGroup[0].valueChanges.subscribe((changedObj: any) => {
      this.disableBtn = this.fomrsGroup[0].valid;
    });
    this.gDataService.getChangePassLoading().subscribe(rs => {
      this.isLoading = rs;
      if (!this.isLoading) {
        this.ChangePasswordDialogRef.close();
      }
    });
  }

  onNoClick(): void {
    this.ChangePasswordDialogRef.close();
  }

  confirm(): void {
    this.gDataService.changePassword(
      this.fomrsGroup[0].value.previousPass,
      this.fomrsGroup[0].value.password,
      this.fomrsGroup[0].value.confirm_password
    );
  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get("password").value; // to get value in input tag
    const confirmPassword = AC.get("confirm_password").value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get("confirm_password").setErrors({ MatchPassword: true });
    } else {
      AC.get("confirm_password").setErrors(null);
    }
  }
}
