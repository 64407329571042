import { NotSessionModule } from "./pages/not-session/not-session.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HostnameService } from "./shared/services/utility/hostname-service/hostname-service";
import { LayoutModule } from "@angular/cdk/layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, APP_INITIALIZER, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxMetrikaModule } from "@kolkov/ngx-metrika";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { KycLayoutComponent } from "./layouts/kyc-layout/kyc-layout.component";
import { PrincipalLayoutComponent } from "./layouts/principal-layout/principal-layout.component";
import { UserLayoutComponent } from "./layouts/user-layout/user-layout.component";
import { TermsDialogComponent } from "./pages/terms-dialog/terms-dialog.component";
import { ChangePasswordComponent } from "./shared/components/change-password/change-password.component";
import { ComponentsModule } from "./shared/components/components.module";
import { MaterialModule } from "./shared/material/material.module";
import {
  InterceptDialog,
  ModalsInterceptor
} from "./shared/services/managers/modals-interceptor/modals-interceptor";
import { PrivateInterceptor } from "./shared/services/managers/private-interceptor/private-interceptor";
import { SidebarManagerService } from "./shared/services/managers/sidebar-manager/sidebar-manager.service";
import { AppConfig } from "./shared/utils/feature-flag/app-config";
import { environment } from "../environments/environment";
import { KycContainerComponent } from "./layouts/kyc-container/kyc-container.component";
import { ENVIRONMENT_TOKEN } from "@ddcheck/common";
import { WhatsappButtonModule } from "./shared/components/buttons/whatsapp-button/whatsapp-button.module";
import { KycUpdateDialogModule } from "./shared/components/kyc-update-dialog/kyc-update-dialog.module";

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
@NgModule({
    declarations: [
        AppComponent,
        UserLayoutComponent,
        PrincipalLayoutComponent,
        InterceptDialog,
        TermsDialogComponent,
        ChangePasswordComponent,
        KycLayoutComponent,
        KycContainerComponent
    ],
    imports: [
        ComponentsModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        AppRoutingModule,
        LayoutModule,
        HttpClientModule,
        FlexLayoutModule,
        NotSessionModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: "toast-top-right",
            preventDuplicates: true
        }),
        WhatsappButtonModule,
        KycUpdateDialogModule
    ],
    providers: [
        SidebarManagerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PrivateInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ModalsInterceptor,
            multi: true
        },
        HostnameService,
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true
        },
        { provide: ENVIRONMENT_TOKEN, useFactory: () => environment }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
