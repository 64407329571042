<div *ngIf="data" ngClass.gt-xs="p-gt-sm">
    <div fxLayout="row" fxLayoutAlign="center">
        <img src="./assets/img/kyc_update.svg" alt="Actualizar kyc">
    </div>
    <p class="title"><strong>¡Se requiere actualización!</strong></p>
    <p class="text">Hola <strong>{{data.name}}</strong>, has superado recientemente el límite de saldo en tu cuenta de
        Fortesza. Para seguir invirtiendo debes actualizar la información de tu debida diligencia y brindar un
        comprobante de dirección y comprobante de ingresos.</p>
    <p class="text">En Fortesza, estamos comprometidos con el cumplimiento de la Ley del 23 de abril de 2015 que adopta medidas
        contra la prevención del blanqueo de capitales.</p>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center" fxLayoutGap="20px" class="m-gt-sm">
        <button mat-raised-button class="button-1" (click)="onNoClick()">Recordar más tarde</button>
        <button mat-raised-button class="button-2" (click)="onNoClick()"
            [routerLink]="['/dashboard/kyc/auth']">Actualizar ahora</button>
    </div>
</div>
