import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { GenericDataApiService } from "../../apis/generic-data-api.service";

@Injectable({
  providedIn: "root"
})
export class GenericDataManagerService {
  private readonly _resData: BehaviorSubject<Countries> = new BehaviorSubject({
    status: "",
    data: []
  });
  private readonly _success: Subject<boolean> = new Subject();
  private readonly _topOffersLoading: Subject<boolean> = new Subject();
  private readonly _publicOffersLoading: Subject<boolean> = new Subject();
  private readonly _publicOffers: BehaviorSubject<any> = new BehaviorSubject({
    status: "",
    data: {
      financed: [],
      org: [],
      top: []
    }
  });
  private readonly _topOffers: BehaviorSubject<any> = new BehaviorSubject({
    status: "",
    data: []
  });
  private readonly _regType: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly _refOpt: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly _changePassLoading: Subject<boolean> = new Subject();

  constructor(private readonly gDataApi: GenericDataApiService) {}

  setRegType(val) {
    /*true para company, false para investor*/
    this._regType.next(val);
  }
  setRefOption(val) {
    this._refOpt.next(val);
  }

  getChangePassLoading() {
    return this._changePassLoading.asObservable();
  }
  getRegType() {
    return this._regType.asObservable();
  }
  getRefOption() {
    return this._refOpt.asObservable();
  }
  getResData() {
    return this._resData.asObservable();
  }
  getLoadingPublicOffers() {
    return this._publicOffersLoading.asObservable();
  }
  getPublicOffers() {
    return this._publicOffers.asObservable();
  }
  getSuccess() {
    return this._success.asObservable();
  }

  fetchCountry() {
    this.gDataApi.fetchCountry().subscribe(
      res => {
        this._resData.next(<Countries>res);
        this._success.next(true);
      },
      err => {
        this._success.next(false);
      }
    );
  }

  fetchTopOfferings() {
    this._topOffersLoading.next(true);
    this.gDataApi.fetchTopOfferings().subscribe(
      res => {
        this._topOffers.next(res);
        this._topOffersLoading.next(false);
      },
      err => {}
    );
  }
  changePassword(previousPass, password, confirmPassword) {
    this._changePassLoading.next(true);
    this.gDataApi
      .changePassword(previousPass, password, confirmPassword)
      .subscribe(
        res => {
          this._changePassLoading.next(false);
        },
        err => {
          this._changePassLoading.next(false);
        }
      );
  }
}

export interface Countries {
  status: string;
  data: any;
}
