<p mat-dialog-title align="center" class="title-done">{{data.title}}</p>
<div mat-dialog-content align="center">
    <div *ngIf="data.text">
        <p style="text-align: justify">{{data.text}}</p>
        <br>
    </div>
    <div *ngIf="data.img">
        <img src="{{data.img}}" alt="" style="max-width: 100px; max-height: 100px;">
        <br>
        <br>
        
        <p style="text-align: center" class="text-done">{{data.text2}}</p>
        <br>
        <br>
    </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutAlign.xs="space-around center" style="padding: 8px 20px;">
    <button (click)="confirm()" class="button-blue" ngStyle.xs="margin-top: 5px">Aceptar</button>
</div>
